import React, { useRef, useEffect } from "react";

import * as dat from "dat.gui";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import styles from "./HeroCanvas.module.css";

const HeroCanvas = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const sceneManager = new SceneManager(canvasRef.current);

        const render = () => {
            requestAnimationFrame(render);
            sceneManager.update();
        }

        render();

        const bindEventListeners = () => {
            window.addEventListener("resize", onResize);
            window.addEventListener("mousemove", onMouseMove);
        }

        const onResize = (event) => {
            sceneManager.onResize(event)
        }

        const onMouseMove = (event) => {
            sceneManager.onMouseMove(event)
        }

        bindEventListeners();

        return () => {
            window.removeEventListener("resize", onResize);
            window.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    return (
        <div className={styles.heroCanvas}>
            <canvas ref={canvasRef}></canvas>
        </div>
    )
}

class SceneManager {
    constructor(canvas) {
        this.canvas = canvas;

        this.createScene();
        this.createCamera();
        this.createRenderer(canvas);
        this.createSceneSubjects();

        this.setupClock();
        this.setupOrbitControls();
    }

    setupOrbitControls = () => {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableZoom = false;
        this.controls.enablePan = false;
        this.controls.rotateSpeed = 0.5;
    }

    createScene = () => {
        this.scene = new THREE.Scene();
    }

    createCamera = () => {
        const aspect = this.canvas.clientWidth / this.canvas.clientHeight;
        const frustumSize = 100;
        
        this.camera = new THREE.OrthographicCamera(
            frustumSize * aspect / -2, 
            frustumSize * aspect / 2, 
            frustumSize / 2, 
            frustumSize / -2, 
            1, 
            2000
        );

        this.camera.position.set(0, 0, 200);
        this.camera.lookAt(0, 0, 0);
    }
    
    createRenderer = () => {
        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, alpha: true });

        const DPR = (window.devicePixelRatio) ? window.devicePixelRatio : 1;
        this.renderer.setPixelRatio(DPR);
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight, false);
    }

    createSceneSubjects = () => {
        this.sceneSubjects = [
            new Logo(this)
        ]
    }

    setupClock = () => {
        this.clock = new THREE.Clock();
        this.clock.start();
    }

    onResize = (event) => {
        this.camera.aspect = this.canvas.clientWidth / this.canvas.clientHeight;
        this.camera.updateProjectionMatrix();

        this.sceneSubjects.forEach(sceneSubject => {
            sceneSubject.onResize(event);
        });
    }

    onMouseMove = (event) => {
        this.sceneSubjects.forEach(sceneSubject => {
            sceneSubject.onMouseMove(event);
        });
    }

    update = () => {
        this.controls.update();
        this.renderer.render(this.scene, this.camera);

        this.sceneSubjects.forEach(sceneSubject => {
            sceneSubject.update();
        });
    }
}

class Logo {
    constructor(manager) {
        this.manager = manager;
        this.parameters = {};
        this.initialRotation = new THREE.Vector3();
        this.shaderUniforms = {
            time: { value: 0 },
            color: { value: new THREE.Color(0xff0000) },
            size: { value: 1.5 },
            mouse: { value: new THREE.Vector2(0, 0) }
        };        


        this.createGeometry();
        this.setupParameters();

        this.mouseX = 0;
        this.mouseY = 0;

        this.mousePosX = 0;
        this.mousePosY = 0;
    }

    createGeometry = () => {
        const ambientLight = new THREE.AmbientLight(0xffffff, 2.5);
        this.manager.scene.add(ambientLight);
        
        const directionalLight = new THREE.DirectionalLight(0xffffff, 2.5);
        directionalLight.position.set(0, 1, 1);
        this.manager.scene.add(directionalLight);
    
        this.group = new THREE.Group();
        this.manager.scene.add(this.group);
    
        const gltfLoader = new GLTFLoader();
        gltfLoader.load("/static/website/assets/3d-models/logo.glb", (gltf) => {
            this.gltf = gltf.scene;
    
            this.gltf.position.set(0, 0, 0);
            this.gltf.rotation.set(3.14 / 2, 0, 0);
            
            this.manager.scene.add(this.gltf); 
    
            if (window.innerWidth >= 320 && window.innerWidth <= 480) {
                this.parameters["scale"] = 0.025; 
            } else if (window.innerWidth >= 481 && window.innerWidth <= 768) {
                this.parameters["scale"] = 0.025; 
            } else if (window.innerWidth >= 769 && window.innerWidth <= 1024) {
                this.parameters["scale"] = 0.023; 
            } else if (window.innerWidth >= 1025 && window.innerWidth <= 1439) {
                this.parameters["scale"] = 150.2; 
            } else if (window.innerWidth >= 1440 && window.innerWidth <= 1679) {
                this.parameters["scale"] = 12.0; 
            } else if (window.innerWidth >= 1680) {
                this.parameters["scale"] = 13.0;
            }
    
            if (this.gltf) {
                this.gltf.scale.set(this.parameters["scale"], this.parameters["scale"], this.parameters["scale"]); 
            }

            const vertexShader = `
                uniform vec2 u_mouse;
                varying vec3 vPosition;
                
                void main() {
                    vPosition = position;
                    
                    // Convert mouse coordinates from screen space (-1 to 1) to world space
                    vec3 mousePos3D = vec3(u_mouse.x, u_mouse.y, 0.0);
                
                    // Calculate the distance from the current vertex to the mouse position
                    float dist = distance(mousePos3D.xy, position.xy);
                
                    // Define the radius within which the repulsion will occur
                    float repulsionRadius = 0.75;
                
                    // If the point is within the repulsion radius, apply a repulsion effect
                    if (dist < repulsionRadius) {
                        // Calculate repulsion direction
                        vec3 dir = normalize(position - mousePos3D);
                        // Apply the repulsion based on distance (closer points are repelled more)
                        float repulsionStrength = (repulsionRadius - dist) / repulsionRadius * 0.2;
                        vPosition += dir * repulsionStrength;
                    }
                
                    // gl_PointSize = 4.0; // Adjust point size if necessary
                    gl_Position = projectionMatrix * modelViewMatrix * vec4(vPosition, 1.0);
                }   
            `;

            // Fragment shader code

            this.gltf.traverse((child) => {
                if (child.isMesh) {
                    this.initialRotation.copy(child.rotation);
                    
                    let fragmentShader; // Declare the variable outside the if-else blocks
            
                    if (child.name == "Mesh") {
                        fragmentShader = `
                            void main() {
                                gl_FragColor = vec4(212.0 / 255.0, 24.0 / 255.0, 118.0 / 255.0, 1.0); // Specific color
                            }`;
                    } else if (child.name == "Mesh_1") {
                        fragmentShader = `
                            void main() {
                                gl_FragColor = vec4(83.0 / 255.0, 193.0 / 255.0, 190.0 / 255.0, 1.0); // Specific color
                            }`;
                    } else if (child.name == "Mesh_2") {
                        fragmentShader = `
                            void main() {
                                gl_FragColor = vec4(252.0 / 255.0, 174.0 / 255.0, 4.0 / 255.0, 1.0); // Specific color
                            }`;
                    }

                    const shaderMaterial = new THREE.ShaderMaterial({
                        uniforms: {
                            u_mouse: { value: new THREE.Vector2() },
                            u_time: { value: 0.0 }
                        },
                        vertexShader, // This uses the vertexShader declared earlier
                        fragmentShader // This now correctly uses the fragmentShader variable
                    });
                    child.material = shaderMaterial;
                }
            });
            

        }, 
        (xhr) => {
            // called while loading is progressing
        },
        (error) => {
            console.error('Error loading GLB:', error);
        });
    }

    setupParameters = () => {
        if (window.innerWidth >= 320 && window.innerWidth <= 480) {
            this.parameters["scale"] = 0.025; 
        } else if (window.innerWidth >= 481 && window.innerWidth <= 768) {
            this.parameters["scale"] = 0.025; 
        } else if (window.innerWidth >= 769 && window.innerWidth <= 1024) {
            this.parameters["scale"] = 0.023; 
        } else if (window.innerWidth >= 1025 && window.innerWidth <= 1439) {
            this.parameters["scale"] = 80.3; 
        } else if (window.innerWidth >= 1440 && window.innerWidth <= 1679) {
            this.parameters["scale"] = 12.0; 
        } else if (window.innerWidth >= 1680) {
            this.parameters["scale"] = 13.0;
        }

        if (this.gltf) {
            this.gltf.scale.set(this.parameters["scale"], this.parameters["scale"], this.parameters["scale"]); 
        }
    }
    
    onResize = (event) => {
        // Update the camera aspect ratio and the renderer size
        this.manager.camera.aspect = this.manager.canvas.clientWidth / this.manager.canvas.clientHeight;
        this.manager.camera.updateProjectionMatrix();
        this.manager.renderer.setSize(this.manager.canvas.clientWidth, this.manager.canvas.clientHeight);


        this.setupParameters(); 
    }

    onMouseMove = (event) => {
        this.mouseX = (event.clientX / window.innerWidth) * 2 - 1;
        this.mouseY = -(event.clientY / window.innerHeight) * 2 + 1;

        this.mousePosX = -(event.clientX - window.innerWidth / 2) * 0.005;
        this.mousePosY = (event.clientY - window.innerHeight / 2) * 0.005;
    }

    update = () => {
        this.shaderUniforms.time.value = this.manager.clock.getElapsedTime();
        
        if (this.gltf) {
            this.gltf.traverse((child) => {
                if (child.isMesh && child.material.isShaderMaterial) {
                    child.material.uniforms.u_mouse.value.x = this.mouseX;
                    child.material.uniforms.u_mouse.value.y = this.mouseY;

                    let newRotationX = this.initialRotation.x + (this.mousePosY - child.rotation.x) * 0.09;
                    let newRotationY = this.initialRotation.y + (this.mousePosX - child.rotation.y) * 0.09;        

                    newRotationX = THREE.MathUtils.clamp(newRotationX, this.initialRotation.x - THREE.MathUtils.degToRad(75), this.initialRotation.x + THREE.MathUtils.degToRad(75));
                    newRotationY = THREE.MathUtils.clamp(newRotationY, this.initialRotation.y - THREE.MathUtils.degToRad(75), this.initialRotation.y + THREE.MathUtils.degToRad(75));
            
                    child.rotation.z = newRotationX;
                    child.rotation.y = newRotationY;
                }
            });
        }
    }
}

export default HeroCanvas
