import React from "react";

import styles from "./SecondaryButton.module.css";

const SecondaryButton = ({ text, href, onClick }) => {
    return (
        <a href={href} target="_blank" className="cursor-pointer bg-transparent border border-[0.5px] border-black font-medium text-neutral-500 font-satoshiVariable text-center xs:px-4 xs:py-4 xs:rounded-xl xs:text-sm 2xl:px-12 2xl:py-4 2xl:rounded-xl 2xl:text-md">{text}</a>
    );
}

export default SecondaryButton;
