import "./tailwind.css";
import styles from "./styles.module.css";

import React from "react";
import { createRoot } from "react-dom/client";

import Backdrop from "../../../components/Backdrop/Backdrop.jsx";
import Header from "../../../components/Header/Header.jsx";
import Hero from "../../../components/Sections/Hero/Hero.jsx";
import TheTeam from "../../../components/Sections/TheTeam/TheTeam.jsx";
import Lab from "../../../components/Sections/Lab/Lab.jsx";
import Technologies from "../../../components/Sections/Technologies/Technologies.jsx";
import DentalSiteMaster from "../../../components/Sections/DentalSiteMaster/DentalSiteMaster.jsx";
import Services from "../../../components/Sections/Services/Services.jsx";
import Portfolio from "../../../components/Sections/Portfolio/Portfolio.jsx";
import ContactUs from "../../../components/Sections/ContactUs/ContactUs.jsx";
import Footer from "../../../components/Footer/Footer.jsx";

const container = document.getElementById("root");
const root = createRoot(container);

const Index = () => {
    return (
        <>
            <div className="hidden fixed z-10 2xl:block 2xl:bottom-12 2xl:right-12">
                <a href="https://api.whatsapp.com/send/?phone=17542707667&text&type=phone_number&app_absent=0" target="_blank">
                    <img className="2xl:w-[60px] 2xl:h-[60px]" src="/static/website/assets/images/whatsapp-logo.png" alt=""></img>
                </a>
            </div>
            <Backdrop></Backdrop>
            <Hero></Hero>
            <main>
                <Header></Header>   
                <TheTeam></TheTeam>
                <Lab></Lab>
                <Technologies></Technologies>
                <DentalSiteMaster></DentalSiteMaster>
                <Services></Services>
                <Portfolio></Portfolio>
                <ContactUs></ContactUs>
                <Footer></Footer>
            </main>
        </>
    );
}

root.render(<Index />);
