import React from "react";

import styles from "./ImagePanel.module.css";

const ImagePanel = ({ selectedImage, setSelectedImage }) => {
    return (
        <>
            <div className="fixed top-0 left-0 z-40 w-screen h-screen bg-[#00000099]" onClick={() => {setSelectedImage("")}}></div>
            <img className="fixed top-1/2 left-1/2 z-50 transform -translate-x-1/2 -translate-y-1/2 2xl:w-[1200px] 2xl:h-[800px] 2xl:rounded-xl object-cover" src={selectedImage} alt="" />
        </>
    );
}

export default ImagePanel;
