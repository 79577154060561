import React from "react";

import styles from "./Footer.module.css";

const Footer = () => {
    return (
        <footer className="flex flex-row justify-between items-center bg-black border-t border-neutral-500 xs:py-6 xs:px-[10%] 2xl:py-6 2xl:px-[5%]">
            <p className="font-satoshiVariable font-medium text-neutral-400 xs:text-sm xs:w-[180px] 2xl:w-auto 2xl:text-sm">
                © Dental Lab Miami 2024. All rights reserved. 
            </p>
            <div className="hidden flex-row justify-center items-center 2xl:flex 2xl:gap-x-12">
                <a href="https://www.instagram.com/dentallabmiamifl?igsh=Mnk2OXBtdWpqeG84&utm_source=qr" target="_blank" className="font-satoshiVariable font-medium text-neutral-400 2xl:text-sm">Instagram</a>
                <a href="https://www.facebook.com/uglytoothtm" target="_blank" className="font-satoshiVariable font-medium text-neutral-400 2xl:text-sm">Facebook</a>
            </div>
        </footer>
    )
}

export default Footer; 
