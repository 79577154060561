import React from "react";

import styles from "./ContactUs.module.css";

const ContactUs = () => {
    return (
        <section id="contact-us" className="flex overflow-x-hidden bg-black xs:flex-col xs:py-12 xs:gap-y-16 2xl:flex-row 2xl:py-40">
            <div className="flex flex-row justify-center items-start 2xl:w-1/2 2xl:py-12">
                <div className="flex flex-col items-start 2xl:w-[500px] xs:gap-y-8 2xl:gap-y-12">
                    <div className="flex flex-col items-start 2xl:gap-y-2">
                        <p className="font-satoshiVariable font-normal text-white xs:hidden xs:text-md 2xl:block 2xl:text-md">say hi to the team</p>
                        <h1 className="font-playfairDisplay font-semibold text-white xs:uppercase xs:text-4xl 2xl:capitalize 2xl:text-8xl">Contact Us</h1>
                    </div>
                    <div className="flex flex-col xs:items-start xs:w-[100%] xs:gap-y-8 2xl:w-[100%] 2xl:gap-y-8">
                        <p className="font-satoshiVariable font-normal text-white xs:w-[280px] xs:leading-[150%] xs:text-sm 2xl:w-[360px] 2xl:leading-[200%] 2xl:text-md">Fill out the form and our team members will get back to you within 24 hours. Or, you came to visit in-person. </p>
                        <form className="flex flex-col xs:w-[300px] xs:gap-y-4 2xl:w-[360px] 2xl:gap-y-16" action="">
                            <div className="flex flex-col justify-center xs:gap-y-4 2xl:gap-y-4">
                                <input className="bg-transparent outline-none border-b border-neutral-500 placeholder:text-neutral-500 placeholder:font-medium text-white xs:px-2 xs:py-2 xs:text-sm 2xl:px-4 2xl:py-4 2xl:text-md" type="text" placeholder="name" />
                                <input className="bg-transparent outline-none border-b border-neutral-500 placeholder:text-neutral-500 placeholder:font-medium text-white xs:px-2 xs:py-2 xs:text-sm 2xl:px-4 2xl:py-4 2xl:text-md" type="text" placeholder="email address" />
                                <input className="bg-transparent outline-none border-b border-neutral-500 placeholder:text-neutral-500 placeholder:font-medium text-white xs:px-2 xs:py-2 xs:text-sm 2xl:px-4 2xl:py-4 2xl:text-md" type="text" placeholder="tell me about it" />
                            </div>
                            <button className="font-satoshiVariable font-normal bg-neutral-700 text-white xs:w-[300px] xs:py-2 xs:text-sm 2xl:w-[360px] 2xl:py-4 2xl:text-md">tell us all about it</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="flex flex-row justify-center 2xl:w-1/2">
                <div className="flex flex-col xs:items-center xs:gap-y-8 2xl:items-start 2xl:w-3/4 2xl:gap-y-16">
                    <iframe className="2xl:w-[80%] 2xl:h-[360px]" loading="lazy" allowFullScreen src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJAQA0s1qq2YgRXwjE8aFzaZk&key=AIzaSyDUY_O2L8yAF6V2Ncx5g4a_542YnmpSh8s&zoom=13"></iframe>
                    <div className="flex flex-col items-start gap-y-2 xs:w-[300px]">
                        <h2 className="font-satoshiVariable font-bold text-neutral-500 xs:text-sm 2xl:text-md">opening hours</h2>
                        <span className="font-satoshiVariable font-medium text-neutral-300 xs:text-sm 2xl:text-md">Monday - Friday</span>
                        <span className="font-satoshiVariable font-medium text-neutral-300 xs:text-sm 2xl:text-md">9AM - 5PM</span>
                        <span className="font-satoshiVariable font-medium text-neutral-300 xs:text-sm 2xl:text-md">Weekend Closed</span>
                    </div>
                    <div className="flex flex-col items-start gap-y-2 xs:w-[300px]">
                        <h2 className="font-satoshiVariable font-bold text-neutral-500 text-sm 2xl:text-md">address</h2>        
                        <p className="font-satoshiVariable font-medium text-neutral-300 text-sm xs:w-[160px] 2xl:text-md 2xl:w-[240px]">1249 Stirling rd, suite 12, Dania Beach FL 33004</p>
                    </div>
                    <div className="flex flex-col items-start gap-y-2 xs:w-[300px]">
                        <h2 className="font-satoshiVariable font-bold text-neutral-500 xs:text-sm 2xl:text-md">support</h2>
                        <span className="font-satoshiVariable font-medium text-neutral-300 xs:text-sm 2xl:text-md">info@miamidentallab.com</span>
                        <span className="font-satoshiVariable font-medium text-neutral-300 xs:text-sm 2xl:text-md">+1 (754) 270 7667</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs
