import React, { useState } from "react";

import styles from "./Portfolio.module.css";

import ImagePanel from "../../Panels/ImagePanel/ImagePanel.jsx";

const Portfolio = () => {
    const totalPortfolios = 2; // Total number of portfolios
    const [selectedImage, setSelectedImage] = useState("");
    const [selectedPortfolio, setSelectedPortfolio] = useState(1);

    const handleNext = () => {
        setSelectedPortfolio(prevSelectedPortfolio => prevSelectedPortfolio === 1 ? 2 : 1);
    };
    
    const handlePrevious = () => {
        setSelectedPortfolio(prevSelectedPortfolio => prevSelectedPortfolio === 1 ? 2 : 1);
    };
    

    const nextPortfolioNumber = (selectedPortfolio % totalPortfolios) + 1;
    const previousPortfolioNumber = (((selectedPortfolio - 2 + totalPortfolios - 1) % totalPortfolios) + 1);

    return (
        <section id="portfolio" className="flex flex-col overflow-x-hidden xs:pt-24 xs:gap-y-12 2xl:pt-40 2xl:gap-y-40">
            {selectedImage !== "" && ( // Use strict inequality for comparison
                <ImagePanel selectedImage={selectedImage} setSelectedImage={setSelectedImage}></ImagePanel>
            )}
            <div className="flex flex-col justify-center items-center 2xl:gap-y-4">
                <span className="uppercase font-satoshiVariable font-light xs:textxs 2xl:text-xl">The</span>
                <h1 className="uppercase font-playfairDisplay font-extrabold xs:text-3xl 2xl:text-7xl">Portfolio</h1>
            </div>
            <div className="flex flex-row justify-center items-center gap-x-24">
                <div className="hidden 2xl:block 2xl:w-2/5 2xl:max-w-[1024px]">
                    <img src={`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-graphics.png`} alt="" />
                </div>
                <div className="flex flex-col xs:w-auto xs:gap-y-8 2xl:w-2/5 2xl:gap-y-12">
                    <h1 className="font-satoshiVariable text-black font-normal xs:w-[320px] xs:text-2xl xs:leading-[120%] 2xl:w-[300px] 2xl:text-5xl 2xl:leading-[120%]">Thinskin
                        <span className="font-satoshiVariable text-secondaryColor font-extrabold "> Feldspathic </span> 
                        Veneers
                    </h1>
                    <p className="font-satoshiVariable font-normal xs:w-[300px] xs:text-sm 2xl:w-[540px] 2xl:text-lg">ThinSkin Veneers are our speciality and most wanted product by dentists. We use two different ways of achieving them : print & press technique or feldspathic on refractory dies, both thin from 0.3-0.5mm , facially guided using digital workflows for repeatable and predictable results. </p>
                    <p className="font-satoshiVariable font-normal xs:w-[300px] xs:text-sm 2xl:w-[540px] 2xl:text-lg">Every patient will get his face scanned, digital impression and get a custom design for his new ideal smile. Those type of veneers are recommended for minimum prep cases just for changing shape and appearance. </p>
                    <div className="grid grid-cols-6 grid-rows-5 gap-x-3 gap-y-3 xs:w-[300px] 2xl:w-[500px]">
                        <div className="cursor-pointer row-start-1 row-end-4 col-start-1 col-end-4" onClick={() => {setSelectedImage(`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-1.jpg`)}}>
                            <img className="object-cover w-full h-full" src={`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-1.jpg`}></img>
                        </div>
                        <div className="cursor-pointer row-start-1 row-end-4 col-start-4 col-end-7" onClick={() => {setSelectedImage(`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-2.jpg`)}}>
                            <img className="object-cover w-full h-full" src={`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-2.jpg`}></img>
                        </div>
                        <div className="cursor-pointer row-start-4 row-end-6 col-start-1 col-end-3" onClick={() => {setSelectedImage(`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-3.jpg`)}}>
                            <img className="object-cover w-full h-full" src={`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-3.jpg`}></img>
                        </div>
                        <div className="cursor-pointer row-start-4 row-end-6 col-start-3 col-end-5" onClick={() => {setSelectedImage(`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-4.jpg`)}}>
                            <img className="object-cover w-full h-full" src={`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-4.jpg`}></img>
                        </div>
                        <div className="cursor-pointer row-start-4 row-end-6 col-start-5 col-end-7" onClick={() => {setSelectedImage(`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-5.jpg`)}}>
                            <img className="object-cover w-full h-full" src={`/static/website/assets/images/thinskin-feldspathic-veneers-${selectedPortfolio}-5.jpg`}></img>
                        </div>
                    </div>
                    <div className="flex flex-row justify-between items-center 2xl:w-[540px]">
                        <div className="flex flex-col items-start gap-y-4" onClick={() => {handlePrevious()}}>
                            <div className="flex flex-col items-start">
                                <span className="font-satoshiVariable font-normal xs:text-sm 2xl:text-xl">Portfolio #{previousPortfolioNumber}</span>
                                <span className="font-satoshiVariable font-light xs:text-xs 2xl:text-md">See Previous</span>
                            </div>
                            <img className="cursor-pointer xs:w-[24px] xs:h-[24px] 2xl:w-[32px] 2xl:h-[32px]" src="/static/website/assets/images/previous-icon.png" alt="" />
                        </div>
                        <div className="flex flex-col items-end gap-y-4" onClick={() => {handleNext()}}>
                            <div className="flex flex-col items-end">
                                <span className="font-satoshiVariable font-normal xs:text-sm 2xl:text-xl">Portfolio #{nextPortfolioNumber}</span>
                                <span className="font-satoshiVariable font-light xs:text-xs 2xl:text-md">See Next</span>
                            </div>
                            <img className="cursor-pointer xs:w-[24px] xs:h-[24px] 2xl:w-[32px] 2xl:h-[32px]" src="/static/website/assets/images/next-icon.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-row xs:justify-center 2xl:justify-end items-center gap-x-24">
                <div className="flex flex-col xs:w-auto xs:gap-y-8 2xl:w-2/5 2xl:gap-y-12">
                    <h1 className="font-satoshiVariable text-black font-normal xs:w-[320px] xs:text-2xl xs:leading-[120%] 2xl:w-[480px] 2xl:text-6xl 2xl:leading-[120%]">
                        <span className="font-satoshiVariable text-secondaryColor font-extrabold"> Press & Press </span><br></br>
                        Veneers
                    </h1>
                    <p className="font-satoshiVariable font-normal xs:w-[300px] xs:text-sm 2xl:w-[540px] 2xl:text-lg">Using full digital workflow we can use same design and shape and texture from mock-up to final restoration. Using high end dental printers and resins to create ideal mock-ups and final restorations using print & press procedures. </p>
                    <p className="font-satoshiVariable font-normal xs:w-[300px] xs:text-sm 2xl:w-[540px] 2xl:text-lg">Final veneers will have cut-back applied and microlayering so custom natural look is achieved. Those can be used to mask natural color of the tooth and change final color to desired one. </p>
                    <div className="grid grid-cols-8 grid-rows-6 gap-x-3 gap-y-3 xs:w-[300px] 2xl:w-[500px]">
                        <div className="cursor-pointer row-start-1 row-end-4 col-start-1 col-end-7" onClick={() => {setSelectedImage("/static/website/assets/images/print-and-press-veneers-1-1.jpg")}}>
                            <img className="object-cover w-full h-full" src="/static/website/assets/images/print-and-press-veneers-1-1.jpg" alt="" />
                        </div>
                        <div className="cursor-pointer row-start-4 row-end-7 col-start-1 col-end-5" onClick={() => {setSelectedImage("/static/website/assets/images/print-and-press-veneers-1-2.jpg")}}>
                            <img className="object-cover w-full h-full" src="/static/website/assets/images/print-and-press-veneers-1-2.jpg" alt="" />
                        </div>
                        <div className="cursor-pointer row-start-4 row-end-7 col-start-5 col-end-9" onClick={() => {setSelectedImage("/static/website/assets/images/print-and-press-veneers-1-4.jpg")}}>
                            <img className="object-cover w-full h-full" src="/static/website/assets/images/print-and-press-veneers-1-4.jpg" alt="" />
                        </div>
                    </div>
                </div>
                <div className="hidden 2xl:block 2xl:w-2/5 2xl:max-w-[1024px]">
                    <img src="/static/website/assets/images/print-and-press-veneers-graphics.png" alt="" />
                </div>
            </div>
            <div className="flex flex-row xs:justify-center 2xl:justify-start items-center gap-x-[12%]">
                <div className="hidden xs:w-auto xs:gap-y-8 2xl:block 2xl:w-2/5 2xl:max-w-[1024px]">
                    <img src="/static/website/assets/images/zirconia-all-on-x-and-ti-bar-graphics.png" alt="" />
                </div>
                <div className="flex flex-col xs:w-auto xs:gap-y-8 2xl:w-2/5 2xl:gap-y-12">
                    <h1 className="font-satoshiVariable text-black font-normal xs:w-[320px] xs:text-2xl xs:leading-[120%] 2xl:text-6xl 2xl:leading-[120%]">Zirconia <br></br>
                        <span className="font-satoshiVariable text-secondaryColor font-extrabold">ALL ON X </span>
                        & Ti Bar 
                    </h1>
                    <p className="font-satoshiVariable font-normal xs:w-[300px] xs:text-sm 2xl:w-[540px] 2xl:text-lg">Using full digital workflow we can use same design and shape and texture from mock-up to final restoration. Using high end dental printers and resins to create ideal mock-ups and final restorations using print & press procedures. </p>
                    <p className="font-satoshiVariable font-normal xs:w-[300px] xs:text-sm 2xl:w-[540px] 2xl:text-lg">Final veneers will have cut-back applied and microlayering so custom natural look is achieved. Those can be used to mask natural color of the tooth and change final color to desired one. </p>
                    <div className="grid grid-cols-10 grid-rows-8 xs:w-[300px] 2xl:w-[500px]">
                        <div className="cursor-pointer row-start-5 row-end-9 col-start-1 col-end-5" onClick={() => {setSelectedImage("/static/website/assets/images/zirconia-all-on-x-and-ti-bar-1-3.jpg")}}>
                            <img className="object-cover w-full h-full" src="/static/website/assets/images/zirconia-all-on-x-and-ti-bar-1-3.jpg" alt="" />
                        </div>
                        <div className="cursor-pointer row-start-1 row-end-5 col-start-5 col-end-11" onClick={() => {setSelectedImage("/static/website/assets/images/zirconia-all-on-x-and-ti-bar-1-4.jpg")}}>
                            <img className="object-cover w-full h-full" src="/static/website/assets/images/zirconia-all-on-x-and-ti-bar-1-4.jpg" alt="" />
                        </div>
                        <div className="cursor-pointer row-start-5 row-end-9 col-start-5 col-end-11" onClick={() => {setSelectedImage("/static/website/assets/images/zirconia-all-on-x-and-ti-bar-1-1.jpg")}}>
                            <img className="object-cover w-full h-full" src="/static/website/assets/images/zirconia-all-on-x-and-ti-bar-1-1.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Portfolio;
