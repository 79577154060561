import React, { useRef, useEffect } from "react";
import * as dat from "dat.gui";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import styles from "./VeneersCanvas.module.css";

const VeneersCanvas = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const sceneManager = new SceneManager(canvasRef.current);

        const render = () => {
            requestAnimationFrame(render);
            sceneManager.update();
        }

        render();

        const bindEventListeners = () => {
            window.addEventListener("resize", onResize);
            window.addEventListener("mousemove", onMouseMove);
        }

        const onResize = (event) => {
            sceneManager.onResize(event)
        }

        const onMouseMove = (event) => {
            sceneManager.onMouseMove(event)
        }

        bindEventListeners();

        return () => {
            window.removeEventListener("resize", onResize);
            window.removeEventListener("mousemove", onMouseMove);
        };
    }, []);

    return (
        <div className={styles.veneersCanvas}>
            <canvas ref={canvasRef}></canvas>
        </div>
    )
}

class SceneManager {
    constructor(canvas) {
        this.canvas = canvas;

        this.createScene();
        this.createCamera();
        this.createRenderer(canvas);
        this.createSceneSubjects();

        this.setupClock();
        this.setupOrbitControls();
    }

    setupOrbitControls = () => {
        this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        this.controls.enableZoom = false;
        this.controls.enablePan = false;
        this.controls.rotateSpeed = 0.5;
        // this.controls.minAzimuthAngle = -Math.PI / 4; // -45 degrees
        // this.controls.maxAzimuthAngle = Math.PI / 4;  // 45 degrees
    }

    createScene = () => {
        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color("#ffffff");
    }

    createCamera = () => {
        const aspect = this.canvas.clientWidth / this.canvas.clientHeight;
        const frustumSize = 100;
        
        this.camera = new THREE.OrthographicCamera(
            frustumSize * aspect / -2, 
            frustumSize * aspect / 2, 
            frustumSize / 2, 
            frustumSize / -2, 
            1, 
            2000
        );

        this.camera.position.set(0, 0, 50);
        this.camera.lookAt(0, 0, 0);
    }
    
    createRenderer = () => {
        this.renderer = new THREE.WebGLRenderer({ canvas: this.canvas, antialias: true, alpha: true });

        const DPR = (window.devicePixelRatio) ? window.devicePixelRatio : 1;
        this.renderer.setPixelRatio(DPR);
        this.renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight, false);
    }

    createSceneSubjects = () => {
        this.sceneSubjects = [
            new ToothObject(this)
        ]
    }

    setupClock = () => {
        this.clock = new THREE.Clock();
        this.clock.start();
    }

    onResize = (event) => {
        this.camera.aspect = window.innerWidth / window.innerHeight;
        this.camera.updateProjectionMatrix();

        this.sceneSubjects.forEach(sceneSubject => {
            sceneSubject.onResize(event);
        });
    }

    onMouseMove = (event) => {
        this.sceneSubjects.forEach(sceneSubject => {
            sceneSubject.onMouseMove(event);
        });
    }

    update = () => {
        this.controls.update();
        this.renderer.render(this.scene, this.camera);

        this.sceneSubjects.forEach(sceneSubject => {
            sceneSubject.update();
        });
    }
}

class ToothObject {
    constructor(manager) {
        this.manager = manager;
        this.parameters = {};
        this.initialRotation = new THREE.Vector3();
        this.createGeometry();
        this.setupParameters();

        this.mouseX = 0;
        this.mouseY = 0;
    }

    createGeometry = () => {
        const ambientLight = new THREE.AmbientLight(0x1A3C35, 0.5);
        this.manager.scene.add(ambientLight);
        
        const directionalLight = new THREE.DirectionalLight(0x1A3C35, 3.5);
        directionalLight.position.set(0, 0, 20);
        directionalLight.lookAt(0, 0, 0);
        this.manager.scene.add(directionalLight);

        const reverseDirectionalLight = new THREE.DirectionalLight(0x1A3C35, 3.5);
        reverseDirectionalLight.position.set(0, 0, -20);
        reverseDirectionalLight.lookAt(0, 0, 0);
        this.manager.scene.add(reverseDirectionalLight);

        this.group = new THREE.Group();
        this.manager.scene.add(this.group);
    
        const gltfLoader = new GLTFLoader();
        gltfLoader.load("/static/website/assets/3d-models/veneers.glb", (gltf) => {
            this.gltf = gltf.scene;
                    
            this.gltf.position.set(0, 0, 0);
            this.gltf.rotation.set(1.57, 0, 0);
            this.initialRotation.copy(this.gltf.rotation);
            this.manager.scene.add(this.gltf); 

            if (window.innerWidth >= 320 && window.innerWidth <= 480) {
                this.parameters["scale"] = 250.7; 
            } else if (window.innerWidth >= 481 && window.innerWidth <= 768) {
                this.parameters["scale"] = 0.025; 
            } else if (window.innerWidth >= 769 && window.innerWidth <= 1024) {
                this.parameters["scale"] = 0.023; 
            } else if (window.innerWidth >= 1025 && window.innerWidth <= 1679) {
                this.parameters["scale"] = 200.7; 
            } else if (window.innerWidth >= 1680) {
                this.parameters["scale"] = 250.7;
            }
    
            if (this.gltf) {
                this.gltf.scale.set(this.parameters["scale"], this.parameters["scale"], this.parameters["scale"]); 
            }
        }, 
        (xhr) => {
            // called while loading is progressing
        },
        (error) => {
            console.error('Error loading GLB:', error);
        });
    }

    setupParameters = () => {
        if (window.innerWidth >= 320 && window.innerWidth <= 480) {
            this.parameters["scale"] = 250.7; 
        } else if (window.innerWidth >= 481 && window.innerWidth <= 768) {
            this.parameters["scale"] = 0.025; 
        } else if (window.innerWidth >= 769 && window.innerWidth <= 1024) {
            this.parameters["scale"] = 0.023; 
        } else if (window.innerWidth >= 1025 && window.innerWidth <= 1679) {
            this.parameters["scale"] = 200.7; 
        } else if (window.innerWidth >= 1680) {
            this.parameters["scale"] = 250.7;
        }

        if (this.gltf) {
            this.gltf.scale.set(this.parameters["scale"], this.parameters["scale"], this.parameters["scale"]); 
        }
    }

    onResize = (event) => {
        this.setupParameters();
    }

    onMouseMove = (event) => {
        this.mouseX = (event.clientX - window.innerWidth / 2) * 0.005;
        this.mouseY = (event.clientY - window.innerHeight / 2) * 0.005;
    }

    update = () => {
        if (this.gltf) {
            let newRotationX = this.initialRotation.x + (this.mouseY - this.gltf.rotation.x) * 0.045;
            let newRotationY = this.initialRotation.y + (this.mouseX - this.gltf.rotation.y) * 0.045;
    
            newRotationX = THREE.MathUtils.clamp(newRotationX, this.initialRotation.x - THREE.MathUtils.degToRad(75), this.initialRotation.x + THREE.MathUtils.degToRad(75));
            newRotationY = THREE.MathUtils.clamp(newRotationY, this.initialRotation.y - THREE.MathUtils.degToRad(75), this.initialRotation.y + THREE.MathUtils.degToRad(75));
    
            this.gltf.rotation.x = newRotationX;
            this.gltf.rotation.y = newRotationY;
        }
    }
}

export default VeneersCanvas
