import React from "react";

import styles from "./TheTeam.module.css";

import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton.jsx";

const TheTeam = () => {
    return (
        <section id="the-team" className="flex flex-col overflow-x-hidden justify-center items-center 2xl:pt-16">
            <div className="flex flex-col justify-center items-center xs:gap-y-16 xs:pb-12 2xl:gap-y-32 2xl:py-0">
                <div className="relative flex flex-col justify-center xs:items-end 2xl:items-start 2xl:flex-row">
                    <div className="2xl:w-2/5 2xl:max-w-[1440px]">
                        <img className="xs:hidden 2xl:block" src="/static/website/assets/images/the-team-miladinov-milos-desktop.png" alt="" />
                        <img className="xs:block 2xl:hidden " src="/static/website/assets/images/the-team-miladinov-milos-mobile.png" alt="" />
                    </div>
                    <div className="flex flex-col xs:w-[300px] 2xl:w-[400px] xs:items-start xs:gap-y-4 xs:mt-8 xs:mr-8 2xl:items-start 2xl:gap-y-8 2xl:mt-16">
                        <h2 className="font-playfairDisplay font-bold text-primaryColor uppercase xs:text-4xl 2xl:w-[360px] 2xl:text-6xl">
                            Miladinov <br></br>
                            <span className="text-secondaryColor">Milos</span>
                        </h2>
                        <p className="font-satoshiVariable font-normal leading-[150%] text-neutral-600 xs:w-[280px] xs:text-sm 2xl:w-[360px] 2xl:text-md">Master Dental Technician, Founder of Dental Lab Miami, DentalTech, and Shoot Like PRO. </p>
                        <p className="font-satoshiVariable font-normal leading-[150%] text-neutral-600 xs:w-[280px] xs:text-sm 2xl:w-[360px] 2xl:text-md">Graduated in 2002 UMFVB Timisoara, focused in Aesthetics, Veneers, Dental Photography, and one of the pioneers of Digital Dentistry Workflows. </p>
                        <SecondaryButton text="Follow Instagram" href="https://www.instagram.com/miladinov_milos/"></SecondaryButton>
                        <SecondaryButton text="Visit Miami Dental Academy" href="https://miamidentalacademy.com/"></SecondaryButton>
                    </div>
                    <div className="hidden absolute -z-10 font-satoshiVariable font-extrabold text-slate-100 2xl:block 2xl:bottom-14 2xl:left-1/2 2xl:text-9xl">
                        <span>Precision</span>
                    </div>
                </div>
                <div className="relative flex justify-center xs:flex-col-reverse 2xl:flex-row">
                    <div className="flex flex-col xs:w-[300px] 2xl:w-[400px] xs:items-start xs:gap-y-4 xs:mt-8 xs:ml-8 2xl:items-start 2xl:gap-y-8 2xl:mt-16">
                        <h2 className="font-playfairDisplay font-bold text-primaryColor uppercase xs:text-4xl 2xl:w-[360px] 2xl:text-6xl">
                            Jorgen <br></br>
                            <span className="text-secondaryColor">Gad</span>
                        </h2>
                        <p className="font-satoshiVariable font-normal leading-[150%] text-neutral-600 xs:w-[280px] xs:text-sm 2xl:w-[360px] 2xl:text-md text-left">UCLA graduated in 2003, with mire than 20 years experience as CDT, MDC. </p>
                        <p className="font-satoshiVariable font-normal leading-[150%] text-neutral-600 xs:w-[280px] xs:text-sm 2xl:w-[360px] 2xl:text-md text-left">Master dental ceramist from Oslo, Norway - founder of Art Pro dental lab and co-founder of Dental Lab Miami. </p>
                        <SecondaryButton text="Follow Instagram"></SecondaryButton>
                    </div>
                    <div className="2xl:w-1/3 2xl:max-w-[1440px]">
                        <img className="xs:hidden 2xl:block" src="/static/website/assets/images/the-team-jorgen-gad-desktop.png" alt="" />
                        <img className="xs:block 2xl:hidden " src="/static/website/assets/images/the-team-jorgen-gad-mobile.png" alt="" />
                    </div>
                    <div className="hidden absolute -z-10 font-satoshiVariable font-extrabold text-slate-100 2xl:block 2xl:bottom-14 2xl:right-2/4 2xl:text-9xl">
                        <span>Devotion</span>
                    </div>
                </div>
            </div>
            <div className="bg-white border border-black w-screen flex flex-row justify-center items-center">
                <div className="xs:w-4/5 xs:py-4 2xl:w-1/3 2xl:max-w-[700px] 2xl:py-4">
                    <span className="uppercase font-robotoMono font-light xs:text-xs 2xl:text-xs italic">Trusted By</span>
                    <div className="flex flex-row justify-between items-center flex-wrap">
                        <img className="xs:basis-[25%] xs:w-[80px] 2xl:basis-[25%] 2xl:w-[140px]" src="/static/website/assets/images/asiga-logo.png" alt="" />
                        <img className="xs:basis-[25%] xs:w-[80px] 2xl:basis-[25%] 2xl:w-[120px]" src="/static/website/assets/images/medit-logo.png" alt="" />
                        <img className="xs:basis-[25%] xs:w-[80px] 2xl:basis-[25%] 2xl:w-[120px]" src="/static/website/assets/images/upcera-logo.png" alt="" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TheTeam;
