import React from "react";

import styles from "./Backdrop.module.css";

const Backdrop = () => {
    return (
        <>
            <div className="fixed flex-row -z-20 top-0 2xl:left-[50%] 2xl:gap-x-[80px] transform -translate-x-1/2 xs:hidden 2xl:flex">
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
                <div className="2xl:h-screen border-l-[0.5px] border-neutral-200"></div>
            </div>
            <div className="fixed flex-row -z-20 top-0 xs:left-[50%] xs:gap-x-[60px] transform -translate-x-1/2 xs:flex 2xl:hidden">
                <div className="xs:h-screen border-l-[0.5px] border-neutral-100"></div>
                <div className="xs:h-screen border-l-[0.5px] border-neutral-100"></div>
                <div className="xs:h-screen border-l-[0.5px] border-neutral-100"></div>
                <div className="xs:h-screen border-l-[0.5px] border-neutral-100"></div>
                <div className="xs:h-screen border-l-[0.5px] border-neutral-100"></div>
                <div className="xs:h-screen border-l-[0.5px] border-neutral-100"></div>
            </div>
        </>
    )
}

export default Backdrop;
