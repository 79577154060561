import React from "react";

import styles from "./Lab.module.css";

const Lab = () => {
    return (
        <section id="lab" className="relative flex flex-row justify-center items-start">
            <img className="absolute -z-10 w-screen object-cover object-bottom xs:h-[880px] 2xl:h-[960px]" src="/static/website/assets/images/lab-background.png" alt="" />
            <div className="flex justify-center items-center xs:flex-col xs:h-[740px] xs:gap-y-8 2xl:flex-row 2xl:h-[700px] 2xl:gap-x-16">
                <div className="xs:w-4/5 2xl:w-[800px]]">
                    <div className="xs:hidden 2xl:grid 2xl:grid-cols-7 2xl:grid-rows-6 2xl:gap-4 2xl:h-[440px]">
                        <img className="object-cover 2xl:w-full 2xl:h-full 2xl:col-start-1 2xl:col-end-3 2xl:row-start-1 2xl:row-end-7" src="/static/website/assets/images/lab-gallery-1.png" alt="" />
                        <img className="object-cover 2xl:w-full 2xl:h-full 2xl:col-start-3 2xl:col-end-8 2xl:row-start-1 2xl:row-end-5" src="/static/website/assets/images/lab-gallery-2.png" alt="" />
                        <img className="object-cover 2xl:w-full 2xl:h-full 2xl:col-start-3 2xl:col-end-5 2xl:row-start-5 2xl:row-end-7" src="/static/website/assets/images/lab-gallery-3.png" alt="" />
                        <img className="object-cover 2xl:w-full 2xl:h-full 2xl:col-start-5 2xl:col-end-8 2xl:row-start-5 2xl:row-end-7" src="/static/website/assets/images/lab-gallery-4.png" alt="" />
                    </div>
                    <img className="xs:block 2xl:hidden object-cover xs:w-full xs:h-[200px]" src="/static/website/assets/images/lab-gallery-2.png" alt="" />
                </div>
                <div className="flex flex-col xs:w-4/5 xs:gap-y-4 2xl:w-[480px] 2xl:gap-y-6">
                    <div>
                        <h1 className="text-white uppercase font-playfairDisplay font-extrabold xs:w-[120px] xs:text-4xl 2xl:text-6xl">Rich History</h1>
                    </div>
                    <p className="text-white font-satoshiVariable font-light xs:text-sm xs:w-auto 2xl:text-md 2xl:w-[400px]">Digital Dental Lab focused on ThinSkin Veneers using all digital workflows and Implant Screw Retained high aesthetics restorations using latest inovations and materials in dental field. </p>
                    <p className="text-white font-satoshiVariable font-light xs:text-sm xs:w-auto 2xl:text-md 2xl:w-[400px]">Constant investments in latest technology, digital equipments that can improve facialy guided restorations like intraoral scanners, face scanners, photogammetry, 3D printing, milling machines and latest CAD softwares. </p>
                    <p className="text-white font-satoshiVariable font-light xs:text-sm xs:w-auto 2xl:text-md 2xl:w-[400px]">All our workflows are 100% for simplified and fast outcomes, easy to apply with any dental office. We will take care of each step of implementation so you can have predictable and repeatable results. </p>
                </div>
            </div>
        </section>
    )
}

export default Lab;
