import React, { useState } from "react";

import styles from "./Hero.module.css";

import HeroCanvas from "../../Canvas/HeroCanvas/HeroCanvas.jsx";
import VideoPanel from "../../Panels/VideoPanel.jsx/VideoPanel.jsx";

const Hero = () => {
    const [selectedVideo, setSelectedVideo] = useState("");

    return (
        <section id="hero" className="relative flex flew-row overflow-x-hidden justify-center items-center h-screen 2xl:gap-x-40">
            {selectedVideo !== "" && ( // Use strict inequality for comparison
                <VideoPanel selectedVideo={selectedVideo} setSelectedVideo={setSelectedVideo}></VideoPanel>
            )}
            <div className="absolute -z-10 bg-white opacity-90 w-screen h-screen"></div>
            <video className="hidden 2xl:block absolute -z-20 w-screen h-screen object-cover" src="/static/website/assets/videos/hero-background-video.mp4" autoPlay muted loop></video>
            <div className="2xl:w-2/5 2xl:max-w-[1000px] 2xl:h-screen">
                <HeroCanvas></HeroCanvas>
            </div>
            <div className="flex flex-col justify-center xs:items-center xs:gap-y-6 2xl:items-end 2xl:w-2/5 2xl:max-w-[600px] 2xl:gap-y-8">
                <div className="flex flex-col justify-center xs:items-center xs:gap-y-2 2xl:items-end 2xl:gap-y-2">
                    <span className="uppercase tracking-[.5em] font-robotoMono font-light text-right xs:text-xs 2xl:text-xs">Introducing</span>
                    <h1 className="uppercase font-playfairDisplay font-extrabold text-right xs:text-center xs:w-[240px] xs:text-6xl 2xl:w-auto 2xl:text-6xl">Dental Lab Miami</h1>
                </div>
                <p className="font-satoshiVariable font-extralight xs:text-center xs:w-[280px] xs:text-sm 2xl:text-right 2xl:w-[520px] 2xl:text-lg">One of the first full digital labs in USA using state of the art high technology combined with passion for details to recreate arhitecture of natural smiles for our patients. </p>
                <p className="font-satoshiVariable font-extralight xs:text-center xs:w-[280px] xs:text-sm 2xl:text-right 2xl:w-[520px] 2xl:text-lg">Founded in 2023 in Miami area, the dental laboratory aims for high end quality restorations for demanding dentists. </p>
                <div className="hidden 2xl:flex flex-row 2xl:w-[480px] 2xl:gap-x-4">
                    <div className="relative cursor-pointer 2xl:w-1/2" onClick={() => {setSelectedVideo("/static/website/assets/videos/hero-video-1.mp4")}}>
                        <video className="2xl:rounded-lg" src="/static/website/assets/videos/hero-video-1.mp4"></video>
                        <img className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src="/static/website/assets/images/play-button.svg" alt="" />
                    </div>
                    <div className="relative cursor-pointer 2xl:w-1/2" onClick={() => {setSelectedVideo("/static/website/assets/videos/hero-video-2.mp4")}}>
                        <video className="2xl:rounded-lg" src="/static/website/assets/videos/hero-video-2.mp4"></video>
                        <img className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src="/static/website/assets/images/play-button.svg" alt="" />
                    </div>
                </div>
            </div>
            <div className="hidden absolute flex-row justify-center items-end 2xl:flex 2xl:left-0 2xl:bottom-12 2xl:-translate-y-[100%]">
                <div className="flex flex-col justify-center items-center -rotate-90 -translate-y-1/2 2xl:gap-y-4">
                    <div className="flex flex-row justify-center items-center 2xl:gap-x-8">
                        <span className="uppercase font-robotoMono font-light 2xl:text-sm">Follow Us</span>
                        <div className="border-[0.5px] border-l-black 2xl:w-[100px] 2xl:h-[0.5px]"></div>
                    </div>
                    <div className="flex flex-row flex-start items-center 2xl:w-full 2xl:gap-x-3">
                        <a href="https://www.facebook.com/uglytoothtm" target="_blank">
                            <img className="2xl:w-[20px] 2xl:h-[20px] rotate-90" src="/static/website/assets/images/hero-facebook-icon.svg" alt="" />
                        </a>
                        <a href="https://www.instagram.com/dentallabmiamifl?igsh=Mnk2OXBtdWpqeG84&utm_source=qr" target="_blank">
                            <img className="2xl:w-[20px] 2xl:h-[20px] rotate-90" src="/static/website/assets/images/hero-twitter-icon.svg" alt="" />
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Hero;
