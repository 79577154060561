import React, { useState } from "react";

import styles from "./Services.module.css";

import VeneersCanvas from "../../Canvas/VeneersCanvas/VeneersCanvas.jsx";
import SuperiorCanvas from "../../Canvas/SuperiorCanvas/SuperiorCanvas.jsx";
import SuperiorMolarsCanvas from "../../Canvas/SuperiorMolarsCanvas/SuperiorMolarsCanvas.jsx";
import WaxUpCanvas from "../../Canvas/WaxUpCanvas/WaxUpCanvas.jsx";

const Services = () => {
    const [ selectedService, setSelectedService ] = useState("veneers");

    const serviceDetails = {
        "veneers": {
            "number": "01",
            "title": "Veneers",
            "description": [
                "ThinSkin Veneers are our speciality and most wanted product by dentists. ", 
                "We use two different ways of achieving them: print & press technique or feldspathic on refractory dies, both facially guided using digital workflows for repeatable and predictable results. "
            ]
        },
        "crowns-and-bridges": {
            "number": "02",
            "title": "Crowns and Bridges",
            "description": [
                "Using top brands of zirconia and silicate to reproduce natural looking teeth is our priority. ", 
                "Dry or wet milled or print & press techniques are guaranteed the most accurate and fast digital workflows. We use custom 3D HD dental libraries so our results have most natural look. "
            ]
        },
        "screw-retained-implant-restoration": {
            "number": "03",
            "title": "Screw Retained Implant Restoration",
            "description": [
                "Using digital impressions, photogammetry and face scanning to create virtual patient and design all in latest CAD softwares is one of our strong points. ", 
                "We have been pioneers of full digital workflow since 2017, publishing articles about it in dental magazines across the world. "
            ]
        },
        "titanium-bar-implant-restoration": {
            "number": "04",
            "title": "Titanium Bar Implant Restoration",
            "description": [
                "In some cases where higher DVO is present and we need Ti reinforced structures to have stronger structure and to minimize the weight of implant structure. ", 
                "This is backed up by extremely precise facially guided workflow. "
            ]
        }
    }

    return (
        <section id="services" className="flex flex-col overflow-x-hidden xs:py-16 2xl:py-40 gap-y-20">
            <div className="flex flex-col justify-center items-center 2xl:gap-y-4">
                <span className="uppercase font-satoshiVariable font-light xs:text-xs 2xl:text-sm">Our</span>
                <h1 className="uppercase font-playfairDisplay font-extrabold xs:text-3xl 2xl:text-6xl">Services</h1>
            </div>
            <div className="flex xs:flex-col xs:gap-y-8 2xl:flex-row justify-center items-center">
                <div className="relative flex flex-col shrink-0 justify-center items-center bg-white border-black xs:w-[340px] xs:h-auto xs:py-[40px] 2xl:w-[800px] 2xl:h-[1080px] 2xl:gap-y-12">
                    <div className="hidden absolute z-10 w-full h-full border-y-[24px] border-darkBackground 2xl:block"></div>
                    <img className="absolute z-20 w-full h-full" src="/static/website/assets/images/service-border.png" alt="" />
                    <div className="hidden absolute z-10 left-[100px] bottom-0 border border-l-darkBackground 2xl:block 2xl:w-[0.5px] 2xl:h-[600px]"></div>
                    <div className="flex flex-row justify-center items-center xs:w-[280px] xs:h-[240px] 2xl:w-[500px] 2xl:h-[400px]">
                        {selectedService == "veneers" && <VeneersCanvas></VeneersCanvas>}
                        {selectedService == "crowns-and-bridges" && <SuperiorMolarsCanvas></SuperiorMolarsCanvas>}
                        {selectedService == "screw-retained-implant-restoration" && <WaxUpCanvas></WaxUpCanvas>}
                        {selectedService == "titanium-bar-implant-restoration" && <SuperiorCanvas></SuperiorCanvas>}
                    </div>
                    <div className="flex flex-col items-center xs:gap-y-2 2xl:gap-y-6">
                        <div className="flex flex-col items-start 2xl:gap-y-1">
                            <span className="font-playfairDisplay font-extrabold inline xs:w-[240px] xs:text-xl 2xl:w-[520px] 2xl:text-3xl">{ serviceDetails[selectedService]["number"] }</span>
                            <h1 className="font-playfairDisplay font-extrabold inline xs:w-[240px] xs:text-4xl 2xl:w-[520px] 2xl:text-6xl">{ serviceDetails[selectedService]["title"] }</h1>
                        </div>
                        {
                            serviceDetails[selectedService]["description"].map((description, index) => (
                                <p key={index} className="font-satoshiVariable font-light leading-loose inline xs:text-sm xs:w-[240px] 2xl:w-[520px] 2xl:text-lg">
                                {description}
                                </p>
                            ))
                        }
                    </div>
                </div>
                <div className="flex-row xs:w-[340px] items-center justify-between xs:flex 2xl:hidden">
                    <span className="font-robotoMono text-[14px]">Click to View</span>
                    <div className="xs:w-[180px] border-b border-neutral-700 "></div>
                </div>
                <div className="flex flex-row justify-center xs:w-[340px] 2xl:w-auto">
                    {selectedService != "veneers" && <div className="flex flex-col xs:items-start 2xl:items-start 2xl:h-[600px] grow bg-darkBackground xs:w-1/3 2xl:w-[200px] xs:py-4 xs:px-2 xs:gap-y-2 2xl:px-8 2xl:pt-28 2xl:gap-y-8" onClick={() => {setSelectedService("veneers")}}>
                        <div className="hidden border border-l-white 2xl:block 2xl:w-[0.25px] 2xl:h-[200px]"></div>
                        <span className="font-playfairDisplay font-light text-white inline 2xl:text-xl">01</span>
                        <h1 className="xs:font-robotoMono 2xl:font-playfairDisplay font-normal text-white inline xs:text-xs 2xl:text-2xl">Veneers</h1>
                    </div>}
                    {selectedService != "crowns-and-bridges" && <div className="flex flex-col xs:items-start 2xl:items-start 2xl:h-[600px] grow bg-darkBackground xs:w-1/3 2xl:w-[200px] xs:py-4 xs:px-2 xs:gap-y-2 2xl:px-8 2xl:pt-28 2xl:gap-y-8" onClick={() => {setSelectedService("crowns-and-bridges")}}>
                        <div className="hidden border border-l-white 2xl:block 2xl:w-[0.25px] 2xl:h-[200px]"></div>
                        <span className="font-playfairDisplay font-light text-white inline 2xl:text-xl">02</span>
                        <h1 className="xs:font-robotoMono 2xl:font-playfairDisplay font-normal text-white inline xs:text-xs 2xl:text-2xl">Crowns <br></br>and Bridges</h1>
                    </div>}
                    {selectedService != "screw-retained-implant-restoration" && <div className="flex flex-col xs:items-start 2xl:items-start 2xl:h-[600px] grow bg-white xs:w-1/3 2xl:w-[200px] xs:py-4 xs:px-2 xs:gap-y-2 2xl:px-8 2xl:pt-28 2xl:gap-y-8" onClick={() => {setSelectedService("screw-retained-implant-restoration")}}>
                        <div className="hidden border border-l-darkBackground 2xl:block 2xl:w-[0.25px] 2xl:h-[200px]"></div>
                        <span className="font-playfairDisplay font-light text-darkBackground inline 2xl:text-xl">03</span>
                        <h1 className="xs:font-robotoMono 2xl:font-playfairDisplay font-normal text-darkBackground inline xs:text-xs 2xl:text-2xl">Screw <br></br>Retained <br></br>Implant <br></br>Restoration</h1>
                    </div>}
                    {selectedService != "titanium-bar-implant-restoration"  && <div className="flex flex-col xs:items-start 2xl:items-start  2xl:h-[600px] grow bg-darkBackground xs:w-1/3 2xl:w-[200px] xs:py-4 xs:px-2 xs:gap-y-2 2xl:px-8 2xl:pt-28 2xl:gap-y-8" onClick={() => {setSelectedService("titanium-bar-implant-restoration")}}>
                        <div className="hidden border border-l-white 2xl:block 2xl:w-[0.25px] 2xl:h-[200px]"></div>
                        <span className="font-playfairDisplay font-light text-white inline 2xl:text-xl">04</span>
                        <h1 className="xs:font-robotoMono 2xl:font-playfairDisplay font-normal text-white inline xs:text-xs 2xl:text-2xl">Titanium <br></br>Bar <br></br>Implant <br></br>Restoration</h1>
                    </div>}
                </div>
            </div>
        </section>
    )
}

export default Services;
