import React from "react";

import styles from "./DentalSiteMaster.module.css";

import SecondaryButton from "../../Buttons/SecondaryButton/SecondaryButton.jsx";

const DentalSiteMaster = () => {
    return (
        <section className="relative flex flex-row overflow-x-hidden justify-center items-center w-screen bg-neutral-950 xs:py-24 2xl:py-24 2xl:gap-x-16">
            <img className="absolute top-0 left-0 w-full h-full object-cover xs:object-left 2xl:object-top-left brightness-75" src="/static/website/assets/images/dental-site-master-background.png" alt="" />
            <div className="hidden absolute 2xl:left-0 2xl:top-0 2xl:h-full 2xl:flex flex-col justify-between 2xl:px-8 2xl:py-8">
                <a href="https://dentalsitemaster.com">
                    <img className="2xl:w-12 2xl:h-12" src="/static/website/assets/images/dental-site-master-logo.png" alt="" />
                </a>
                <div className="flex flex-col items-center 2xl:gap-y-4">
                    <a href="https://www.facebook.com/dentalsitemaster/" target="_blank">
                        <img className="2xl:w-4 2xl:h-4" src="/static/website/assets/images/dental-site-master-facebook-icon.png" alt="" />
                    </a>
                    <a href="https://www.instagram.com/dental_site_master/" target="_blank">
                        <img className="2xl:w-4 2xl:h-4" src="/static/website/assets/images/dental-site-master-instagram-icon.png" alt="" />
                    </a>
                    <a href="https://www.youtube.com/@dentalsitemaster" target="_blank">
                        <img className="2xl:w-4 2xl:h-4" src="/static/website/assets/images/dental-site-master-youtube-icon.png" alt="" />
                    </a>
                    <a href="https://api.whatsapp.com/send/?phone=13124095146&text&type=phone_number&app_absent=0" target="_blank">
                        <img className="2xl:w-4 2xl:h-4" src="/static/website/assets/images/dental-site-master-whatsapp-icon.png" alt="" />
                    </a>
                </div>
            </div>
            <div className="hidden relative 2xl:flex flex-row 2xl:w-2/5 2xl:max-w-[880px]">
                <video className="object-cover 2xl:w-full 2xl:h-[520px]" src="https://dentalsitemaster.s3.amazonaws.com/email-subscription-video.mp4" autoPlay muted controls></video>
            </div>
            <div className="relative flex flex-col w-auto xs:items-center 2xl:items-start xs:gap-y-6 2xl:gap-y-8">
                <h1 className="text-white font-satoshiVariable font-extrabold xs:text-center xs:text-4xl xs:w-[300px] 2xl:text-left 2xl:w-[560px] 2xl:text-6xl">3D Dental Websites<br></br> Never Seen Before</h1>
                <p className="text-white font-satoshiVariable font-light xs:text-center xs:text-sm xs:w-[300px] 2xl:text-left 2xl:w-[640px] 2xl:text-lg">Build personality and trust by encouraging users to keep scrolling, using the latest technologies and a team from Silicon Valley. </p>
                <p className="text-white font-satoshiVariable font-light xs:text-center xs:text-sm xs:w-[300px] 2xl:text-left 2xl:w-[640px] 2xl:text-lg">With a Computer Science degree from Illinois Institute of Technology and a rich 12-year background in creating high-performing Real Estate & E-commerce websites, I've mastered the art of crafting digital platforms that drive sales. </p>
                <video className="hidden xs:block xs:w-[300px] 2xl:hidden" src="https://dentalsitemaster.s3.amazonaws.com/email-subscription-video.mp4" autoPlay muted controls></video>
                <div className="flex xs:flex-col xs:gap-y-4 2xl:flex-row 2xl:gap-x-8">
                    <a className="cursor-pointer bg-transparen bg-dental-site-master-gradient text-white text-center font-semibold font-satoshiVariable xs:w-[300px] xs:py-3 xs:px-8 xs:rounded-lg xs:text-sm 2xl:py-4 2xl:px-8 2xl:rounded-lg 2xl:text-md" href="https://dentalsitemaster.com" target="_blank">Get Mine Today!</a>
                    <a className="cursor-pointer bg-transparen border border-white border-[0.5px] text-white text-center font-semibold font-satoshiVariable xs:w-[300px] xs:py-3 xs:px-8 xs:rounded-lg xs:text-sm 2xl:py-4 2xl:px-8 2xl:rounded-lg 2xl:text-md">Download FREE SEO Checklist</a>              
                </div>
            </div>
        </section>
    )
}

export default DentalSiteMaster;
