import React, { useState } from "react";

import styles from "./Header.module.css";

const Header = () => {
    const [showMenu, setShowMenu] = useState(false);

    return (
        <header className="flex flex-row justify-center items-center 2xl:py-12">
            <nav className="xs:hidden 2xl:flex flex-row justify-center items-center 2xl:flex 2xl:gap-x-12">
                <a className="font-robotoMono 2xl:text-sm" href="#the-team">The Team</a>
                <a className="font-robotoMono 2xl:text-sm" href="#lab">Lab</a>
                <a className="font-robotoMono 2xl:text-sm" href="#technologies">Technologies</a>
                <a className="font-robotoMono 2xl:text-sm" href="#serivces">Services</a>
                <a className="font-robotoMono 2xl:text-sm" href="#portfolio">Portfolio</a>
                <a className="font-robotoMono 2xl:text-sm" href="#contact-us">Contact Us</a>
            </nav>
            <nav className="xs:fixed z-50 bg-white z-10 top-0 flex-row justify-between items-center xs:flex xs:w-full xs:px-[24px] xs:py-[12px] 2xl:hidden ">
                <a className="" href="#hero">
                    <img className="w-[40px]" src="/static/website/assets/images/dental-lab-miami-logo.png" alt="" />
                </a>
                <div className="relative">
                    <a className="" onClick={() => {setShowMenu(!showMenu)}}>
                        <img className="w-[20px]" src="/static/website/assets/images/header-menu-icon.png" alt="" />
                    </a>
                    { showMenu && (
                        <div className="fixed flex bg-[#ffffff99] backdrop-blur-md flex-col items-center justify-center xs:w-screen xs:h-screen xs:right-0 xs:top-[59px] xs:gap-y-[24px]">
                            <a className="font-robotoMono text-sm text-darkBackground" href="#the-team" onClick={() => {setShowMenu(false)}}>The Team</a>
                            <a className="font-robotoMono text-sm text-darkBackground" href="#lab" onClick={() => {setShowMenu(false)}}>Lab</a>
                            <a className="font-robotoMono text-sm text-darkBackground" href="#technologies" onClick={() => {setShowMenu(false)}}>Technologies</a>
                            <a className="font-robotoMono text-sm text-darkBackground" href="#services" onClick={() => {setShowMenu(false)}}>Services</a>
                            <a className="font-robotoMono text-sm text-darkBackground" href="#portfolio" onClick={() => {setShowMenu(false)}}>Portfolio</a>
                            <a className="font-robotoMono text-sm text-darkBackground" href="#contact-us" onClick={() => {setShowMenu(false)}}>Contact Us</a>
                        </div>
                    )}
                </div>
            </nav>
        </header>
    );
}

export default Header;
