import React, { useState } from "react";

import styles from "./Technologies.module.css";

import TechnologyDetailsPanel from "../../Panels/TechnologyDetailsPanel/TechnologyDetailsPanel.jsx";

const Technologies = () => {
    const [selectedTechnology, setSelectedTechnology] = useState("");
    const [currentTechnology, setCurrentTechnology] = useState("milling-machine");

    const technologyDetails = {
        "milling-machine": {
            "number": "01",
            "first-word": "Milling",
            "second-word": "Machine",
            "image-src": "/static/website/assets/images/milling-machine-display.png"
        },
        "ceramic-furnaces": {
            "number": "02",
            "first-word": "Ceramic",
            "second-word": "Furnaces",
            "image-src": "/static/website/assets/images/ceramic-furnaces-display.png"
        },
        "compute-engine": {
            "number": "03",
            "first-word": "Compute",
            "second-word": "Engine",
            "image-src": "/static/website/assets/images/compute-engine-display.png"
        },
        "3d-face-scanners": {
            "number": "04",
            "first-word": "3D",
            "second-word": "Face Scanners",
            "image-src": "/static/website/assets/images/3d-face-scanners-display.png"
        },
        "photogrametry": {
            "number": "05",
            "first-word": "Photogrametry",
            "second-word": "",
            "image-src": "/static/website/assets/images/photogrametry-display.png"
        },
        "3d-printer": {
            "number": "06",
            "first-word": "3D",
            "second-word": "Printer",
            "image-src": "/static/website/assets/images/3d-printer-display.png"
        },
        "intraoral-scanners": {
            "number": "07",
            "first-word": "Intraoral",
            "second-word": "Scanners",
            "image-src": "/static/website/assets/images/intraoral-scanners-display.png"
        },
        "renfert-equipments": {
            "number": "08",
            "first-word": "Renfert",
            "second-word": "Equipments",
            "image-src": "/static/website/assets/images/renfert-equipments-display.png"
        },
        "cad-software": {
            "number": "09",
            "first-word": "CAD",
            "second-word": "Software",
            "image-src": "/static/website/assets/images/cad-software-display.png"
        }
    }

    return (
        <section id="technologies" className="flex flex-col overflow-x-hidden items-center justify-center xs:pt-20 xs:pb-12 xs:gap-y-12 2xl:pt-80 2xl:pb-40 2xl:gap-y-12">
            {selectedTechnology !== "" && ( // Use strict inequality for comparison
                <TechnologyDetailsPanel selectedTechnology={selectedTechnology} setSelectedTechnology={setSelectedTechnology}></TechnologyDetailsPanel>
            )}
            <div className="flex flex-col justify-center items-center 2xl:gap-y-2">
                <span className="uppercase font-satoshiVariable font-light xs:text-xs 2xl:text-sm">Our</span>
                <h1 className="uppercase font-playfairDisplay font-extrabold xs:text-3xl 2xl:text-6xl">Technologies</h1>
            </div>
            <div className="flex xs:flex-col-reverse 2xl:flex-row justify-center items-center 2xl:gap-x-8">
                <div className="flex xs:flex-row 2xl:flex-col 2xl:w-auto xs:gap-x-4 2xl:gap-y-4">
                    <span className="font-extrabold font-satoshiVariable leading-none xs:text-[140px] 2xl:text-[320px]">{technologyDetails[currentTechnology]["number"]}</span>
                    <div className="flex flex-col xs:justify-center 2xl:gap-y-4">
                        <h2 className="font-extrabold text-secondaryColor xs:text-3xl xs:w-[140px] 2xl:text-5xl">
                            {technologyDetails[currentTechnology]["first-word"]}
                            <span className="text-neutral-800"> {technologyDetails[currentTechnology]["second-word"]}</span>
                        </h2>
                        <div className="flex flex-row justify-start items-center 2xl:gap-x-4">
                            <span className="cursor-pointer xs:bg-transparent 2xl:bg-white font-normal font-robotoMono 2xl:text-lg" onClick={() => {setCurrentTechnology("/static/website/assets/images/milling-machine-display.png")}}>Learn More</span>
                            <hr className="bg-neutral-800 2xl:w-44 2xl:h-[2px]"></hr>
                        </div>
                    </div>
                </div>
                <img className="2xl:w-1/2 2xl:max-w-[1400px]" src={technologyDetails[currentTechnology]["image-src"]} alt="" />
            </div>
            <div className="flex flex-row xs:flex-start 2xl:justify-center xs:w-4/5 xs:h-[140px] xs:overflow-scroll xs:scrollbar xs:scrollbar-thumb-gray-900 xs:scrollbar-track-gray-100 xs:scrollbar-thumb-rounded 2xl:flex-wrap 2xl:w-2/3 2xl:max-w-[1400px] 2xl:h-[100%] 2xl:gap-[16px]">
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("ceramic-furnaces")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-40 2xl:w-full 2xl:h-3/4" src="/static/website/assets/images/ceramic-furnace-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">02</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">Ceramic Furnaces</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("compute-engine")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/compute-engine-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">03</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">Compute Engine</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("3d-face-scanners")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/3d-face-scanners-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">04</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">3D Face Scanners</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("photogrametry")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/photogrametry-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">05</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">Photogrametry</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("3d-printer")}}>  
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/3d-printer-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">06</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">3D Printer</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("intraoral-scanners")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/intraoral-scanners-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">07</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">Intraoral Scanners</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("renfert-equipments")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/renfert-equipments-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">08</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">Renfert Equipments</span>
                </div>
                <div className="flex flex-col xs:w-[160px] xs:grow-0 xs:shrink-0 2xl:basis-[calc(25%-16px)] 2xl:gap-y-2" onClick={() => {setCurrentTechnology("cad-software")}}>
                    <div className="relative cursor-pointer xs:bg-transparent 2xl:bg-white 2xl:border 2xl:border-neutral-400 2xl:rounded-xl xs:h-[60%] 2xl:w-auto 2xl:h-48">
                        <img className="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 xs:h-full xs:object-contain xs:w-[120px] 2xl:w-full 2xl:h-full 2xl:h-3/4" src="/static/website/assets/images/cad-software-thumbnail.png" alt="" />
                        <span className="absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-extrabold text-neutral-100 xs:text-[80px] 2xl:text-[160px]">09</span>
                    </div>
                    <span className="font-normal font-robotoMono text-neutral-800 text-center xs:text-xs 2xl:text-sm">CAD Software</span>
                </div>
            </div>
        </section>
    )
}

export default Technologies;
